<template>
  <div>
    <a-modal
      :visible="visible.modalEditJabatan"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditJabatan"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        JABATAN
      </h3>
      <p>
        Apakah anda yakin mau memperbarui nama jabatan ini? Setelah diperbarui,
        data lama jabatan ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="jabatanSearch"
          @keyup="searchJabatan"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama Jabatan...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.jabatan">
          <a-button size="large" @click.prevent="toEditJabatan" icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        v-if="edit.jabatan"
        :columns="jabatanColumns"
        :data-source="jabatanDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeJabatan"
        bordered
      >
        <div slot="jabatan" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'jabatan')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
        <div slot="fungsional" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'fungsional')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
        <div slot="operasional" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'operasional')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
        <div slot="transportasi" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'transportasi')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
        <div slot="makan" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'makan')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
        <div slot="lainLain" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'lainLain')
            "
          >{{thousandPrice(text)}}</a-input>
        </div>
      </a-table>
      <a-table
        v-else
        :columns="jabatanColumns"
        :data-source="jabatanDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeJabatan"
        bordered
      >
        <div slot="jabatan" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
        <div slot="fungsional" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
        <div slot="operasional" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
        <div slot="transportasi" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
        <div slot="makan" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
        <div slot="lainLain" slot-scope="text">
          {{thousandPrice(text)}}
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import thousandPrice from '@/helpers/thousandPrice'

const jabatanColumns = [
  {
    title: 'Nama Jabatan',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    scopedSlots: { customRender: 'unit' },
    fixed: 'left',
    width: 150,
    align: 'center',
  },
  {
    title: 'Tunjangan',
    dataIndex: 'tunjangan',
    key: 'tunjangan',
    scopedSlots: { customRender: 'tunjangan' },
    align: 'center',
    children: [
      {
        title: 'Jabatan (%)',
        dataIndex: 'jabatan',
        key: 'jabatan',
        scopedSlots: { customRender: 'jabatan' },
        align: 'center',
        width: 150,
      },
      {
        title: 'Fungsional (%)',
        dataIndex: 'fungsional',
        key: 'fungsional',
        scopedSlots: { customRender: 'fungsional' },
        align: 'center',
        width: 150,
      },
      {
        title: 'Operasional (Rp)',
        dataIndex: 'operasional',
        key: 'operasional',
        scopedSlots: { customRender: 'operasional' },
        align: 'center',
        width: 150,
      },
      {
        title: 'Makan (Rp)',
        dataIndex: 'makan',
        key: 'makan',
        scopedSlots: { customRender: 'makan' },
        align: 'center',
        width: 150,
      },
      {
        title: 'Transportasi (Rp)',
        dataIndex: 'transportasi',
        key: 'transportasi',
        scopedSlots: { customRender: 'transportasi' },
        align: 'center',
        width: 150,
      },
      {
        title: 'Lain-Lain (Rp)',
        dataIndex: 'lainLain',
        key: 'lainLain',
        scopedSlots: { customRender: 'lainLain' },
        align: 'center',
        width: 150,
      },
    ],
  },
]

export default {
  name: 'master-jabatan',
  data() {
    return {
      thousandPrice,
      jabatanColumns,
      jabatanDataTable: [],
      savedJabatanData: [],
      visible: {
        modalAddJabatan: false,
        modalEditJabatan: false,
      },
      loading: {
        table: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      jabatanPage: 'all',
      jabatanSearch: '',
      jabatanOrder: '',
      jabatanSortBy: '',
      jabatanName: '',
      edit: {
        jabatan: false,
      },
      userAdmin: {
        finance: false,
        hr: false,
      },
      listUnit: [],
      filterUnit: [],
      selectUnit: '',
    }
  },
  methods: {
    handleEditJabatanChange(value, key, column) {
      const newData = [...this.jabatanDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedJabatanData = newData
      }
    },
    handleTableChangeJabatan(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else if (sorter.field === 'unit') {
        sortBy = 'nama'
      } else {
        sortBy = ''
      }
      this.fetchDataJabatan({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.cancel = boolean
    },
    searchJabatan() {
      if (this.jabatanSearch.length > 2) {
        this.fetchDataJabatan({
          search: this.jabatanSearch,
        })
      } else {
        this.fetchDataJabatan({
          search: this.jabatanSearch,
        })
      }
    },
    fetchDataJabatan(
      params = {
        order: this.jabatanOrder,
        sortBy: this.jabatanSortBy,
        search: this.jabatanSearch,
        page: this.jabatanPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_JABATAN_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.listUnit = res.listUnit
          this.jabatanDataTable = res.data.map((row) => {
            const jabatan =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_jabatan
            const fungsional =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_fungsional
            const operasional =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_operasional
            const makan =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_makan
            const transportasi =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_transportasi
            const lainLain =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_lain
            return {
              key: row.id,
              id_unit: row.id_unit,
              nama: row.nama,
              unit: row.unit.nama,
              jabatan,
              fungsional,
              operasional: operasional,
              makan: makan,
              transportasi: transportasi,
              lainLain: lainLain,
            }
          })
          this.savedJabatanData = this.jabatanDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalEditJabatan = false
    },
    toEditJabatan() {
      this.edit.jabatan = true
    },
    saveEdit() {
      this.visible.modalEditJabatan = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataJabatan()
      setTimeout(() => {
        this.edit.jabatan = false
        this.handleAllLoading(false)
      })
    },
    validateJabatanDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].jabatan === '' || data[i].jabatan === '-' || data[i].jabatan === null) {
          data[i].jabatan = 0
        } else if (isNaN(Number(data[i].jabatan))) {
          isInvalid = true
          message = `Maaf tunjangan jabatan ke-${i + 1}, harus angka!`
          break
        }
        if (data[i].fungsional === '' || data[i].fungsional === '-' || data[i].fungsional === null) {
          data[i].fungsional = 0
        } else if (isNaN(Number(data[i].fungsional))) {
          isInvalid = true
          message = `Maaf tunjangan fungsional ke-${i + 1}, harus angka!`
          break
        }
        if (data[i].operasional === '' || data[i].operasional === '-' || data[i].operasional === null) {
          data[i].operasional = 0
        } else if (isNaN(Number(data[i].operasional))) {
          isInvalid = true
          message = `Maaf tunjangan operasional ke-${i + 1}, harus angka!`
          break
        }
        if (data[i].transportasi === '' || data[i].transportasi === '-' || data[i].transportasi === null) {
          data[i].transportasi = 0
        } else if (isNaN(Number(data[i].transportasi))) {
          isInvalid = true
          message = `Maaf tunjangan transportasi ke-${i + 1}, harus angka!`
          break
        }
        if (data[i].makan === '' || data[i].makan === '-' || data[i].makan === null) {
          data[i].makan = 0
        } else if (isNaN(Number(data[i].makan))) {
          isInvalid = true
          message = `Maaf tunjangan makan ke-${i + 1}, harus angka!`
          break
        }
        if (data[i].lainLain === '' || data[i].lainLain === '-' || data[i].lainLain === null) {
          data[i].lainLain = 0
        } else if (isNaN(Number(data[i].lainLain))) {
          isInvalid = true
          message = `Maaf tunjangan lainLain ke-${i + 1}, harus angka!`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditJabatan() {
      const newData = [...this.savedJabatanData]
      if (newData.length) {
        const validation = this.validateJabatanDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditJabatan = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              nama: row.nama,
              id_unit: row.id_unit,
              unit: row.unit,
              jabatan: row.jabatan,
              fungsional: row.fungsional,
              operasional: row.operasional,
              makan: row.makan,
              transportasi: row.transportasi,
              lainLain: row.lainLain,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminKeuangan/UPDATE_JABATAN', {
              data: target,
            })
            .then((res) => {
              setTimeout(() => {
                this.fetchDataJabatan()
                this.edit.jabatan = false
                this.visible.modalEditJabatan = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Jabatan berhasil diperbarui',
                  })
                } else {
                  this.$notification.error(
                    {
                      message: 'Gagal',
                      description: 'Jabatan tidak berhasil diperbarui',
                    },
                    1000,
                  )
                }
              })
            })
        }
      } else {
        this.edit.jabatan = false
        this.visible.modalEditJabatan = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Jabatan tidak berhasil diperbarui',
          })
        })
      }
    },
  },
  mounted() {
    this.fetchDataJabatan()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
